import React from "react";
import NodeSiteTeaser from "components/Site/NodeSiteTeaser";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";

export default function NodeSiteList({ nodes, variant = "institution" }) {
  const { t } = useTranslation("common");
  const themes = {
    default: "bg-default",
    primary: "bg-primary",
    institution: "bg-institution",
    formation: "bg-formation",
    internship: "bg-internship",
    news: "bg-news",
    inscription: "bg-inscription",
  };

  return (
    <>
      <div
        className={classNames(
          "hidden xl:block absolute top-0 inset-x-0 h-1/2 bg-opacity-80",
          themes[variant]
        )}
        aria-hidden="true"
      >
        <div
          className="absolute top-1/2 inset-x-0 h-40 bg-squares-pattern bg-contain bg-repeat-x opacity-20 -translate-y-1/2"
          aria-hidden="true"
        />
      </div>
      <div className="relative container">
        <h2 className="sr-only">{t()}</h2>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 2xl:gap-20">
          {nodes.map((node) => {
            return (
              <div key={node.id}>
                <NodeSiteTeaser node={node} variant={variant} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
