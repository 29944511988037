import React from "react";
export default function IconClassroom({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 105.91 113.107"
      className={className}
      aria-hidden="true"
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      >
        <path d="M98.507 33.939v-4.6a2.943 2.943 0 1 0-5.885 0v-3.82a2.942 2.942 0 0 0-2.942-2.943h0a2.943 2.943 0 0 0-2.943 2.943v3.82a2.942 2.942 0 1 0-5.884 0V39.76h-.032l.028 3.909.009-.761.022 6.045s-2.5-.983-2.719-1.081a35.332 35.332 0 0 1-3.458-4.247 2.963 2.963 0 1 0-5.243 2.76l4.366 6.107L83.957 63.62l-2.584 34.712" />
        <path d="m103.916 98.332-2.573-34.747 3.063-4.868V39.66h-.029v-5.7a2.943 2.943 0 0 0-2.942-2.942h0a2.926 2.926 0 0 0-2.923 2.942M86.738 29.339v11.434M92.609 29.339v12.114M80.804 48.937a11.981 11.981 0 0 1 7.111 6.474M98.517 34.093v8.543M7.401 33.939v-4.6a2.943 2.943 0 1 1 5.885 0v-3.82a2.943 2.943 0 0 1 2.942-2.943h0a2.943 2.943 0 0 1 2.946 2.943v3.82a2.943 2.943 0 1 1 5.885 0V39.76h.031l-.028 3.909-.009-.761-.022 6.045s2.5-.983 2.719-1.081a35.333 35.333 0 0 0 3.458-4.247 2.963 2.963 0 1 1 5.243 2.76l-4.366 6.107L21.953 63.62l2.584 34.712M1.993 98.332l2.568-34.747-3.062-4.868V39.66h.028v-5.7a2.943 2.943 0 0 1 2.943-2.942h0a2.926 2.926 0 0 1 2.922 2.942M19.172 29.339v11.434M13.301 29.339v12.114" />
        <path d="M25.104 48.937a11.984 11.984 0 0 0-7.111 6.474M7.392 34.093v8.543M47.203 10.45V6.828a2.318 2.318 0 1 1 4.635 0V3.819a2.318 2.318 0 0 1 2.318-2.318h0a2.318 2.318 0 0 1 2.318 2.318v3.009a2.318 2.318 0 0 1 4.636 0v8.209h.024l-.021 3.078-.008-.6-.018 4.762s1.972-.775 2.142-.852a27.924 27.924 0 0 0 2.723-3.345 2.334 2.334 0 1 1 4.131 2.174l-3.439 4.809-7.977 8.765 1.685 25.511M43.098 59.341l1.869-25.539-2.413-3.835v-15.01h.022v-4.491a2.319 2.319 0 0 1 2.318-2.318h0a2.3 2.3 0 0 1 2.3 2.318M56.474 6.827v9.006M51.849 6.827v9.542" />
        <path d="M61.145 22.264a9.437 9.437 0 0 0-5.6 5.1M47.195 10.572v6.729M61.949 73.731v-4.6a2.943 2.943 0 0 0-5.885 0v-3.82a2.942 2.942 0 0 0-2.943-2.942h0a2.942 2.942 0 0 0-2.942 2.942v3.82a2.943 2.943 0 0 0-5.885 0v10.422h-.031l.028 3.908.009-.761.023 6.046s-2.5-.984-2.719-1.082a35.479 35.479 0 0 1-3.457-4.246 2.963 2.963 0 0 0-5.244 2.76l4.367 6.106 10.128 11.128-.6 8.088M65.343 110.882l-.555-7.506 3.067-4.868V79.452h-.028v-5.7a2.943 2.943 0 0 0-2.943-2.943h0a2.926 2.926 0 0 0-2.923 2.943M50.179 69.131v11.434M56.05 69.131v12.114" />
        <path d="M44.249 88.728a11.982 11.982 0 0 1 7.11 6.474M61.958 73.885v8.543" />
      </g>
    </svg>
  );
}
