import React from "react";
export default function IconStudent({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 119.358 110.157"
      className={className}
      aria-hidden="true"
    >
      <g
        transform="translate(1.5 1.893)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      >
        <path d="m46.842 36.332 19.033 15.461a4.183 4.183 0 0 1 .55 5.955h0a4.182 4.182 0 0 1-5.823.537L46.02 46.439M101.575 50.941l2.222 9.786 7.436.712a3.481 3.481 0 0 1 3.064 3.854h0a3.481 3.481 0 0 1-3.854 3.064l-5.48-.684" />
        <circle
          cx="9.307"
          cy="9.307"
          r="9.307"
          transform="rotate(-18.2 31.678 -81.882)"
        />
        <path d="M26.945 34.787a9.723 9.723 0 0 1 10.282-8.26h0a9.721 9.721 0 0 1 9.04 10.225l-1.6 26.926 21.961 31.833a5.823 5.823 0 0 1-.2 8.578h0a5.822 5.822 0 0 1-7.722-.038l-33.16-41.142.215-4.514M11.405 65.993 6.27 65.66l2.026-31.205a5.8 5.8 0 0 1 6.167-5.415l6.454.418a1.13 1.13 0 0 1 1.053 1.2l-1.019 15.684" />
        <path d="m0 65.252 6.27.407 1.384-21.31h0a6.284 6.284 0 0 0-6.678 5.863ZM73.447 50.941l.117-2.5a5.4 5.4 0 0 1 5.5-5.338l2.559.138a1.07 1.07 0 0 1 .968 1.149l-1.177 25.1-8.811-.477.423-9.029Z" />
        <path d="m67.811 63.29-.255 5.445 5.044.273.393-8.387M35.816 75.641l-3.764 26.04a5.811 5.811 0 0 1-6.142 5.068h0a5.811 5.811 0 0 1-5.388-6.531l5.032-36.313M35.816 38.039 21.009 67.097a4.669 4.669 0 0 1-6.28 2.04h0a4.67 4.67 0 0 1-2.041-6.28l14.8-29.054" />
        <circle
          cx="6.939"
          cy="6.939"
          r="6.939"
          transform="rotate(-19.303 121.492 -242.08)"
        />
        <path d="m99.999 70.258-.07 1.191 15.642 23.759a4.655 4.655 0 0 1-1.854 6.747h0a4.656 4.656 0 0 1-5.843-1.509L85.765 69.007l.9-18.852a7.248 7.248 0 0 1 7.735-6.846h0a7.246 7.246 0 0 1 6.74 7.624l-.632 10.7M93.651 80.917l-3.108 18.428a4.334 4.334 0 0 1-4.58 3.778h0a4.332 4.332 0 0 1-4.017-4.869l3.82-28.444" />
        <path d="m95.137 50.5.258 10.031 7.153 2.157a3.482 3.482 0 0 1 2.248 4.38h0a3.482 3.482 0 0 1-4.381 2.248l-12.47-4.156.228-14.65" />
      </g>
    </svg>
  );
}
