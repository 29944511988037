import Body from "components/Body";
import Button from "components/Button";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function NodeSiteTeaser({ node, variant = "institution" }) {
  const { t } = useTranslation("common");
  const themes = {
    default: {
      title: "text-default",
      decoration: "bg-default",
    },
    primary: {
      title: "text-primary",
      decoration: "bg-primary",
    },
    institution: {
      title: "text-institution",
      decoration: "bg-institution",
    },
    formation: {
      title: "text-formation",
      decoration: "bg-formation",
    },
    internship: {
      title: "text-internship",
      decoration: "bg-internship",
    },
    news: {
      title: "text-news",
      decoration: "bg-news",
    },
    inscription: {
      title: "text-inscription",
      decoration: "bg-inscription",
    },
  };
  return (
    <div className="relative xl:pt-8 xl:pr-14">
      <div className="relative xl:pl-20 xl:pr-14 xl:pb-14 bg-white shadow-lg">
        <div className="relative xl:-top-8 xl:-mr-28 xl:mb-8">
          <div className="aspect-w-4 aspect-h-3">
            <Image
              src={node.field_image.image_style_uri.portrait}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </div>
        </div>
        <div className="p-8 xl:p-8">
          <h3
            className={classNames(
              "font-important font-bold text-2xl",
              themes[variant].title
            )}
          >
            {node.title}
          </h3>
          {node.body && (
            <>
              <div
                className={classNames(
                  "h-1 w-10 mt-3 xl:mt-6",
                  themes[variant].decoration
                )}
                aria-hidden="true"
              />
              <div className="prose max-w-none text-lg mt-6">
                <Body value={node.body.summary || node.body.processed} />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="text-right">
        <Link href={node.path.alias} passHref>
          <Button as="a" theme={variant} size="small" shadow>
            {t("utils.more_link")}
          </Button>
        </Link>
      </div>
    </div>
  );
}
