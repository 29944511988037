import React from "react";
export default function IconTeacher({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 111.121 111.75"
      className={className}
      aria-hidden="true"
    >
      <defs>
        <clipPath id="clip-teacher">
          <path fill="none" d="M0 0h111.121v111.75H0z" />
        </clipPath>
      </defs>
      <g
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="3.5"
        clipPath="url(#clip-teacher)"
      >
        <path d="M78.956 36.848c-5.358 0-9.358-4.494-9.358-10.029v-3.346c0-5.535 4-10.025 9.358-10.025s9.358 4.49 9.358 10.025v3.346c.001 5.535-4 10.029-9.358 10.029ZM67.26 111.711V60.24s-7.6 4.679-9.358 4.679-5.447-1.624-9.358-4.679a71.325 71.325 0 0 1-5.568-4.834 3.374 3.374 0 0 1 3.549-5.566c3.657 1.371 7.344 2.754 9.038 3.381 3.507-3.658 7.162-9.358 16.375-9.358h16.377c8.383 0 11.7 3.537 11.7 14.038v14.037c0 5.018-2 7.019-7.019 7.019h-2.34M78.75 78.75v33M90.75 57.75v54" />
        <path d="M60.24 88.315H1.75V1.75h107.621v86.565h-11.7" />
      </g>
    </svg>
  );
}
