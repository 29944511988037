import React from "react";
import Image from "next/image";
import { A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import classNames from "lib/classNames";

export default function ImageSwiper({ images, variant = "institution" }) {
  const themes = {
    default: {
      background: "bg-default",
    },
    primary: {
      background: "bg-primary",
    },
    institution: {
      background: "bg-institution",
    },
    formation: {
      background: "bg-formation",
    },
    internship: {
      background: "bg-internship",
    },
    news: {
      background: "bg-news",
    },
    inscription: {
      background: "bg-inscription",
    },
  };

  return (
    <div className={classNames("relative", themes[variant])}>
      <Swiper
        slidesPerView={1}
        modules={[A11y, Pagination]}
        pagination={{
          clickable: true,
          bulletClass:
            "image-swiper-pagination relative inline-block mx-2 h-4 w-4 rounded-full transition-colors duration-300",
          bulletActiveClass: classNames(
            "image-swiper-pagination-active",
            themes[variant].background
          ),
        }}
      >
        {images.map((image) => {
          return (
            <SwiperSlide key={image.id} className="aspect-w-16 aspect-h-10">
              <Image
                src={image.image_style_uri.medium_16by10}
                {...image.resourceIdObjMeta}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
