import React from "react";

export default function IconTopRightPattern({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 222 222"
      className={className}
      aria-hidden="true"
    >
      <g transform="translate(1515 -2827)" fill="currentColor">
        <path d="M-1515 2827h111v111h-111zM-1404 2938h111v111h-111z" />
        <circle
          cx="15.5"
          cy="15.5"
          r="15.5"
          transform="translate(-1363 2868)"
        />
      </g>
    </svg>
  );
}
