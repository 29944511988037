import { PlusIcon } from "@heroicons/react/outline";
import Body from "components/Body";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function TermProfileTeaser({ term }) {
  const { t } = useTranslation("common");
  return (
    <div className="group relative">
      <div className="relative p-2">
        <div className="aspect-w-1 aspect-h-1">
          <Image
            src={term.field_image.image_style_uri.portrait}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            className="rounded-[58px]"
          />
        </div>
        <div className="absolute inset-0 scale-100 group-hover:scale-105 transition-transform duration-500">
          <Link href={term.path.alias} passHref>
            <a className="absolute inset-0 flex items-center justify-center bg-formation text-white rounded-[58px] bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-500">
              <span className="inline-block p-2 bg-formation rounded-full scale-0 group-hover:scale-100 transition-transform duration-500">
                <PlusIcon className="h-12 w-12" />
              </span>
              <span className="sr-only">{t("utils.more_link")}</span>
            </a>
          </Link>
          <div className="absolute left-0 bottom-0 bg-white p-4 w-3/4">
            <h3 className="font-important font-bold text-xl">
              {term.field_display_name || term.name}
            </h3>
            {term.description && (
              <div className="prose max-w-none text-lg">
                <Body value={term.description.processed} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
